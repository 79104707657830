<template>
  <div style="mdt-info" v-if="room_info">
    <div class="mb-4 border-bottom d-flex justify-content-between header-info">
      <p class="mb-2 robo-16-500">Thông tin phòng hội chẩn</p>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="hideInfo"
        class="cursor-pointer"
      >
        <path
          d="M6 17.9998L17.9998 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 17.9998L6 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div>
      <p class="mb-2 robo-24-500 txt-black" v-if="room_info">
        {{ room_info.name }}
      </p>
      <p class="mb-4 robo-18-400 txt-black">
        {{ mdtUtils.getTimeStringDetail(room_info) }}
      </p>
      <div class="mb-4" v-if="room_info.location">
        <p class="mb-2 robo-18-500">Địa điểm</p>
        <p class="mb-2 robo-16-400">{{ room_info.location }}</p>
      </div>

      <div class="mb-4" :set="(link = mdtUtils.linkRoom(room_info))">
        <p class="mb-2 robo-18-500 txt-black">Link phòng</p>
        <div class="row">
          <div class="col-6">
            <p
              class="block mb-2 robo-16-400 mb-0 txt-black max-line-1 truncate"
            >
              {{ link || "..." }}
            </p>
          </div>
          <div class="col-6 d-flex align-items-center justify-content-end">
            <a
              href="javascript:;"
              @click="copyLink(link)"
              class="txt-pri text-decoration-underline txt-16-400 d-flex align-items-center"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2"
              >
                <path
                  d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.60003C3.60003 22 2.00003 20.4 2.00003 16.4V12.6C2.00003 8.6 3.60003 7 7.60003 7H10.6"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.6 2H15.6"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.00003 5C7.00003 3.34 8.34003 2 10 2H12.62"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 8V14.19C22 15.74 20.74 17 19.19 17"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z"
                  stroke="#20419B"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Sao chép link
            </a>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-2 robo-18-500 txt-black">Thành viên tham gia</p>
        <div class="mb-3" v-if="room_members && room_members.length">
          <div
            v-for="mem in room_members"
            :key="mem.id + 'mem'"
            class="d-flex align-items-center justify-content-between item-doctor p-0 mb-3"
            :set="(status = getStatus(mem.doctor_id))"
          >
            <div
              class="d-flex align-items-center"
              :set="(user = mem.doctor.user)"
            >
              <span
                v-if="user && user.avatar"
                class="avatar avatar-sm avatar-rounded mr-2"
                :style="
                  'background-image: url(' +
                  appUtils.getImageURL(user.avatar) +
                  ')'
                "
              ></span>
              <span
                v-if="user && !user.avatar"
                class="avatar avatar-sm avatar-rounded mr-2"
              >
                {{ user && user.name ? user.name.charAt(0) : "" }}
              </span>
              <div>
                <p class="robo-14-400 txt-black mb-0 max-line-1">
                  {{ mem.doctor ? mem.doctor.name : "" }}
                </p>
                <p class="robo-12-400 txt-grey-900 mb-0 max-line-1">
                  {{ mdtUtils.getRoleName(mem.role) }}
                </p>
              </div>
            </div>
            <p class="mb-0 robo-14-400" :class="status ? status.class : ''">
              {{ status.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
const statusCheckin = [
  { stt: 0, text: 'Đang chờ', class: 'text-warning' },
  { stt: 1, text: 'Đã điểm danh', class: 'text-success' },
  { stt: 2, text: 'Không tham gia', class: 'text-danger' }
]
export default {
  name: 'MdtInfo',
  props: ['room_info', 'room_members', 'checkin_members'],
  data () {
    return {
      mdtUtils,
      appUtils,
      statusCheckin
    }
  },
  methods: {
    getStatus (id) {
      let status = 0
      if (this.checkin_members && this.checkin_members?.data?.length) {
        let checkin = this.checkin_members.data.find(
          (c) => parseInt(c.doctor_id) === parseInt(id)
        )
        if (checkin) {
          status = checkin.status
        }
      }
      return this.statusCheckin[status]
    },
    copyLink (link) {
      let url = document.createElement('input')
      document.body.appendChild(url)
      url.value = link
      url.select()
      url.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open({
        message: 'Đã sao chép vào bộ nhớ tạm',
        type: 'success'
      })
      url.remove()
    },
    hideInfo () {
      this.$emit('hideInfo')
    }
  }
}
</script>

<style scoped>
.mdt-info {
  background: #fff;
  color: black;
}
.header-info {
  width: 100%;
}
</style>

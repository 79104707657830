<template>
  <div>
    <div v-show="showing_room && !loading_room">
      <div class="mb-3 flex items-center">
        <div class="robo-24-500 mb-1 txt-black mr-3">
          {{ (showing_room && showing_room.name) || "..." }}
        </div>

        <div>
          <button
            v-if="showing_room && !loading_room"
            class="btn bg-pri bd-pri text-white py-2"
            @click="goToRoom(showing_room)"
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2"
            >
              <path
                d="M11.5 7L10.1 8.4L12.7 11H2.5V13H12.7L10.1 15.6L11.5 17L16.5 12L11.5 7ZM20.5 19H12.5V21H20.5C21.6 21 22.5 20.1 22.5 19V5C22.5 3.9 21.6 3 20.5 3H12.5V5H20.5V19Z"
                fill="white"
              />
            </svg>
            {{ $t("multidisciplinary_board.btn_join_room") }}
          </button>
        </div>
      </div>
      <div class="mdt-detail-body">
        <div class="row m-0 mb-3">
          <div class="col-2 d-flex align-items-center p-0">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2"
            >
              <path
                d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9Z"
                fill="#1D2939"
              />
              <path
                d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
                fill="#1D2939"
              />
            </svg>

            <p class="fs-16 txt-grey-900 mb-0">
              {{ $t("multidisciplinary_board.lbl_location") }}:
            </p>
          </div>
          <div class="col-10">
            <p class="fs-16 fw-600 txt-grey-900 mb-0 max-line-1">
              {{ (showing_room && showing_room.location) || "..." }}
            </p>
          </div>
        </div>
        <div
          class="row m-0 mb-3"
          v-if="showing_room"
          :set="(link = mdtUtils.linkRoom(showing_room))"
        >
          <div class="col-2 d-flex align-items-center p-0">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2"
            >
              <path
                d="M17 7H13V9H17C18.65 9 20 10.35 20 12C20 13.65 18.65 15 17 15H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM11 15H7C5.35 15 4 13.65 4 12C4 10.35 5.35 9 7 9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15ZM8 11H16V13H8V11Z"
                fill="black"
              />
            </svg>

            <p class="fs-16 txt-grey-900 mb-0">
              {{ $t("multidisciplinary_board.lbl_room_link") }}:
            </p>
          </div>
          <div class="col-10 d-flex align-items-center">
            <p class="block fs-16 fw-600 txt-grey-900 mb-0 max-line-1 truncate">
              {{ link || "..." }}
            </p>
            <a
              href="javascript:;"
              @click="copyLink(link)"
              class="txt-pri fs-16 fw-600 d-flex align-items-center ml-3"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2"
              >
                <path
                  d="M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM19.5 5H8.5C7.4 5 6.5 5.9 6.5 7V21C6.5 22.1 7.4 23 8.5 23H19.5C20.6 23 21.5 22.1 21.5 21V7C21.5 5.9 20.6 5 19.5 5ZM19.5 21H8.5V7H19.5V21Z"
                  fill="#20419B"
                />
              </svg>
              {{ $t("multidisciplinary_board.btn_copy_link") }}
            </a>
          </div>
        </div>

        <!-- member -->
        <div class="row m-0 mb-3">
          <div
            class="mb-3 px-0 d-flex align-items-center justify-content-between"
          >
            <div class="d-flex align-items-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2"
              >
                <path
                  d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z"
                  fill="black"
                />
              </svg>

              <p class="mb-0 fs-24 fw-600 txt-grey-900">
                {{ $t("multidisciplinary_board.lbl_participant") }} ({{
                  room_members && room_members.length ? room_members.length : 0
                }})
              </p>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6"
            v-for="mem in room_members"
            :key="mem.id + 'mem'"
          >
            <div class="dropdown">
              <div
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div
                  class="d-flex align-items-center justify-content-between item-doctor p-0 bg-white"
                  :class="
                    (doctor_member && doctor_member.role === 1) ||
                    (showing_room && doctor_id === showing_room.doctor_id)
                      ? 'form-select-arrow pl-3'
                      : 'form-control'
                  "
                >
                  <template v-if="mem.doctor && mem.doctor.user">
                    <div
                      class="d-flex align-items-center"
                      :set="(user = mem.doctor.user)"
                    >
                      <span
                        v-if="user && user.avatar"
                        class="avatar avatar-sm avatar-rounded mr-2 border"
                        :style="
                          'background-image: url(' +
                          getImageURL(user.avatar) +
                          ')'
                        "
                      ></span>
                      <span
                        v-if="user && !user.avatar"
                        class="avatar avatar-sm avatar-rounded mr-2 border"
                        >{{
                          user && user.name ? user.name.charAt(0) : ""
                        }}</span
                      >
                      <div>
                        <p class="robo-14-400 txt-black mb-0 max-line-1">
                          {{ mem.doctor ? mem.doctor.name : "" }}
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="ml-2"
                            v-if="mem.type === 1"
                          >
                            <path
                              d="M6.58579 9.41452C7.36684 10.1956 8.63316 10.1956 9.41421 9.41452C10.1953 8.63347 10.1953 7.36714 9.41421 6.58609C8.63316 5.80504 7.36684 5.80504 6.58579 6.58609C5.80474 7.36714 5.80474 8.63347 6.58579 9.41452Z"
                              fill="#20409B"
                            />
                            <ellipse
                              cx="2.66682"
                              cy="7.99983"
                              rx="1.99983"
                              ry="1.99983"
                              fill="#20409B"
                            />
                            <path
                              d="M10 2.66602C10 3.77059 9.10457 4.66602 8 4.66602C6.89543 4.66602 6 3.77059 6 2.66602C6 1.56145 6.89543 0.666016 8 0.666016C9.10457 0.666016 10 1.56145 10 2.66602Z"
                              fill="#20409B"
                            />
                            <path
                              d="M10 13.334C10 14.4386 9.10457 15.334 8 15.334C6.89543 15.334 6 14.4386 6 13.334C6 12.2294 6.89543 11.334 8 11.334C9.10457 11.334 10 12.2294 10 13.334Z"
                              fill="#20409B"
                            />
                            <ellipse
                              cx="13.3328"
                              cy="7.99983"
                              rx="1.99983"
                              ry="1.99983"
                              fill="#20409B"
                            />
                          </svg>
                        </p>
                        <p class="robo-12-400 txt-grey-900 mb-0 max-line-1">
                          {{ mdtUtils.getRoleName(mem.role) }}
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div
                class="dropdown-menu w-100"
                aria-labelledby="dropdownMenuButton"
                v-if="
                  (doctor_member && doctor_member.role === 1) ||
                  (showing_room && doctor_id === showing_room.doctor_id)
                "
              >
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  @click="changeRole(1, mem.doctor_id)"
                  >{{ $t("multidisciplinary_board.lbl_mems_type_chairman") }}</a
                >
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  @click="changeRole(2, mem.doctor_id)"
                  >{{ $t("multidisciplinary_board.lbl_participant") }}</a
                >
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  @click="changeRole(3, mem.doctor_id)"
                  >{{ $t("multidisciplinary_board.lbl_mems_type_expert") }}</a
                >
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  @click="changeRole(4, mem.doctor_id)"
                >
                  {{ $t("multidisciplinary_board.lbl_mems_type_secretary") }}
                </a>
                <div
                  v-if="
                    mem &&
                    doctor_id !== mem.doctor_id &&
                    showing_room &&
                    mem.doctor_id !== showing_room.doctor_id
                  "
                >
                  <hr class="m-0 p-0" />
                  <a
                    class="dropdown-item border-t"
                    href="javascript:;"
                    @click="removeMember(mem)"
                    >{{ $t("button.delete") }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- info -->
        <div>
          <div class="mb-3">
            <MdtRoomSearch
              :room="mdtRoomInfo"
              :isShowFindingCase="isShowFindingCase"
              @createMdtDisease="createMdtDisease"
              @setSearchInput="setSearchInput"
              @setIsShowFindingCase="setIsShowFindingCase"
            />
          </div>

          <div
            :class="
              searchInput.length || isShowFindingCase
                ? 'invisible opacity-0 h-0 overflow-hidden'
                : 'visible opacity-100 h-auto'
            "
            class="transition-all duration-300"
          >
            <div class="my-6">
              <div class="flex items-center justify-between gap-2">
                <div class="d-flex align-items-center justify-content-start">
                  <div class="d-flex align-items-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2"
                    >
                      <path
                        d="M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V8H20V18Z"
                        fill="black"
                      />
                    </svg>
                    <p class="mb-0 fs-24 fw-600 txt-grey-900">
                      {{ $t("multidisciplinary_board.lbl_case") }} ({{
                        pending_cases_count ? pending_cases_count.total : 0
                      }})
                    </p>
                  </div>
                  <a
                    href="javascript:;"
                    class="btn bg-pri bd-pri text-white py-2 ml-3"
                    role="button"
                    @click="createMdtDisease"
                    >{{ $t("multidisciplinary_board.btn_add_case") }}</a
                  >
                </div>
                <div class="flex items-center justify-between gap-4 col-6">
                  <input
                    class="form--input h-10 mt-1"
                    type="date"
                    placeholder="Chọn ngày dự kiến mổ"
                    v-model="filterDate"
                  />
                  <button
                    :disabled="!filterDate"
                    @click="handlePrintListOperation"
                    class="btn bg-pri text-white w-60"
                  >
                    {{ $t("multidisciplinary_board.btn_operation_print") }}
                  </button>
                </div>
              </div>
              <div v-if="sessions">
                <div
                  class="d-flex p-3 align-items-center item-session cursor-pointer"
                  v-for="session in sessions.data"
                  :key="session.id + 'session'"
                  @click="goToMdtSession(session.id)"
                >
                  <div class="text-center">
                    <svg
                      width="52"
                      height="52"
                      viewBox="0 0 52 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mb-2"
                    >
                      <path
                        d="M43.333 6.49935H41.1663V4.33268C41.1663 3.14102 40.1913 2.16602 38.9997 2.16602C37.808 2.16602 36.833 3.14102 36.833 4.33268V6.49935H15.1663V4.33268C15.1663 3.14102 14.1913 2.16602 12.9997 2.16602C11.808 2.16602 10.833 3.14102 10.833 4.33268V6.49935H8.66634C6.28301 6.49935 4.33301 8.44935 4.33301 10.8327V45.4994C4.33301 47.8827 6.28301 49.8327 8.66634 49.8327H43.333C45.7163 49.8327 47.6663 47.8827 47.6663 45.4994V10.8327C47.6663 8.44935 45.7163 6.49935 43.333 6.49935ZM41.1663 45.4994H10.833C9.64134 45.4994 8.66634 44.5243 8.66634 43.3327V17.3327H43.333V43.3327C43.333 44.5243 42.358 45.4994 41.1663 45.4994Z"
                        fill="#20409B"
                      />
                    </svg>
                    <p class="fs-20 txt-grey-900 mb-0">
                      {{ formatDateDMY(session.date) }}
                    </p>
                  </div>
                  <div class="px-5 border-right">
                    <p class="mb-2 fs-32 fw-600 txt-pri">
                      {{ session.mdt_cases_count }}
                    </p>
                    <p class="fs-20 txt-grey-900 mb-0">
                      {{ $t("multidisciplinary_board.lbl_type_mdt") }}
                    </p>
                  </div>
                  <div class="px-5">
                    <p class="mb-2 fs-32 fw-600 txt-pri">
                      {{ session.ts_cases_count }}
                    </p>
                    <p class="fs-20 txt-grey-900 mb-0">
                      {{ $t("multidisciplinary_board.lbl_type_surgery") }}
                    </p>
                  </div>
                </div>
                <div class="text-center">
                  <a
                    v-if="
                      sessions &&
                      sessions.current_page < sessions.last_page &&
                      !loading_session
                    "
                    href="javascript:;"
                    @click="getMdtSessions(showing_room.id, true)"
                    class="fs-16 fw-500 txt-pri"
                  >
                    {{ $t("multidisciplinary_board.btn_more") }}
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 9.5L12.5 14.5L17.5 9.5H7.5Z"
                        fill="#20419B"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div v-if="loading_session">
                <div class="skeleton-line h-136px"></div>
                <div class="skeleton-line h-136px"></div>
              </div>
            </div>

            <div class="mb-3">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                  >
                    <path
                      d="M20 6H12L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM20 18H4V8H20V18Z"
                      fill="black"
                    />
                  </svg>
                  <p class="mb-0 fs-24 fw-600 txt-grey-900">
                    {{ $t("multidisciplinary_board.lbl_case_closed") }} ({{
                      finished_cases_count ? finished_cases_count.total : 0
                    }})
                  </p>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-6 p-3 cursor-pointer"
                  @click="goToFinishedDisease(0)"
                >
                  <div class="radius-18 border p-3">
                    <div class="d-flex align-items-center">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2"
                      >
                        <path
                          d="M30 9H18L15 6H6C4.35 6 3.015 7.35 3.015 9L3 27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V12C33 10.35 31.65 9 30 9Z"
                          fill="#FFA043"
                        />
                      </svg>
                      <p class="mb-0 fs-18 fw-500 txt-grey-800">
                        {{ $t("multidisciplinary_board.lbl_type_mdt") }}
                      </p>
                    </div>
                    <p class="text-center fs-32 fw-600 txt-pri mb-2">
                      {{ finished_cases_count ? finished_cases_count.mdt : 0 }}
                    </p>
                  </div>
                </div>
                <div
                  class="col-md-6 p-3 cursor-pointer"
                  @click="goToFinishedDisease(1)"
                >
                  <div class="radius-18 border p-3">
                    <div class="d-flex align-items-center">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-2"
                      >
                        <path
                          d="M30 9H18L15 6H6C4.35 6 3.015 7.35 3.015 9L3 27C3 28.65 4.35 30 6 30H30C31.65 30 33 28.65 33 27V12C33 10.35 31.65 9 30 9Z"
                          fill="#FFA043"
                        />
                      </svg>
                      <p class="mb-0 fs-18 fw-500 txt-grey-800">
                        {{ $t("multidisciplinary_board.lbl_type_surgery") }}
                      </p>
                    </div>
                    <p class="text-center fs-32 fw-600 txt-pri mb-2">
                      {{ finished_cases_count ? finished_cases_count.ts : 0 }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100">
      <div
        class="mdt-detail-body d-flex align-items-center justify-content-center"
        v-if="loading_room"
      >
        <div class="spinner-border mt-5" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <ModalAddMember
      ref="ModalAddMember"
      v-if="showing_room != {} && !!showing_room"
      @refresh="refreshMember"
      :room="showing_room"
      :showing_date="showing_next_date"
    />
  </div>
</template>

<script>
import ModalAddMember from './ModalAddMember.vue'
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
import MdtRoomSearch from './MdtRoomSearch.vue'
export default {
  name: 'MdtRoomDetail',
  components: { ModalAddMember, MdtRoomSearch },
  props: ['showing_room', 'loading_room'],
  data () {
    return {
      room_members: [],
      doctor_member: null,
      showing_date: window.moment(),
      showing_next_date: window.moment(),
      room_diseases: [],
      mdtUtils,
      appUtils,
      params_disease: {
        sort: 'created_at',
        sort_by: 'asc',
        status: 0,
        search: '',
        page: 0,
        loading: false
      },
      doctor_id: null,
      showing_disease: null,
      sessions: [],
      loading_session: false,
      searchInput: '',
      isShowFindingCase: false,
      filterDate: '',
      finished_cases_count: {},
      pending_cases_count: {},
      mdtRoomInfo: {}
    }
  },
  mounted () {
    this.doctor_id = appUtils.getLocalUser()?.doctor?.id
  },
  watch: {
    showing_room (room) {
      if (!room) return

      this.mdtRoomInfo = room
      this.getMdtMembers(room.id)
      this.getMdtSessions(room.id)
      this.handleGetTotalMdtCase(room.id)
    }
  },
  methods: {
    setSearchInput (value) {
      this.searchInput = value
    },
    setIsShowFindingCase (value) {
      this.isShowFindingCase = value
    },
    async getMdtMembers (id) {
      let self = this
      let params = {}
      self.$rf
        .getRequest('DoctorRequest')
        .getMdtMembers(id, params)
        .then((r) => {
          self.room_members = r.data
          self.mdtRoomInfo = {
            ...self.showing_room,
            members: r.data
          }

          self.doctor_member = r.data.find(
            (md) => md.doctor.id === self.doctor_id
          )
        })
    },
    async getMdtSessions (id, is_next) {
      let self = this
      let page = 1
      if (!is_next) {
        this.room_diseases = []
      } else {
        page = this.sessions?.current_page + 1
      }
      let params = {
        sort: 'date',
        sort_by: 'desc',
        page: page,
        limit: 2
      }
      this.loading_session = true

      this.$rf
        .getRequest('DoctorRequest')
        .getMdtSession(id, params)
        .then((r) => {
          if (r && r.data) {
            self.sessions.current_page = r.data.current_page
            if (!is_next) {
              self.sessions = r.data
            } else {
              if (r.data?.count) {
                let old = self.sessions.data
                let newObj = r.data.data
                self.sessions.data = old.concat(newObj)
              }
            }
          }
        })
        .finally(() => {
          self.loading_session = false
        })
    },
    formatDMYHm (date, time) {
      return mdtUtils.createDateTime(date, time).format('DD/MM/YYYY HH:mm')
    },
    formatHm (time) {
      return mdtUtils.createDateTime(time).format('HH:mm')
    },
    formatDMY (time) {
      return window.moment(time).format('DD/MM/YYYY')
    },
    async changeRole (role, doctor_id) {
      let self = this
      if (self.showing_room && role && doctor_id) {
        let params = {
          doctor_id: doctor_id,
          role: role
        }
        self.$rf
          .getRequest('DoctorRequest')
          .mdtChangeRole(self.showing_room.id, params)
          .then(() => {
            self.getMdtMembers(self.showing_room?.id)
          })
      }
    },
    async removeMember (m) {
      let self = this
      let strcf = this.$t('multidisciplinary_board.lbl_del_confirm')
      let r = confirm(strcf)
      if (!r) return
      this.$rf
        .getRequest('DoctorRequest')
        .removeMdtMember(m.id)
        .then((r) => {
          self.$toast.open({
            message: this.$t('multidisciplinary_board.lbl_deleted'),
            type: 'success'
          })
          self.getMdtMembers(self.showing_room?.id)
        })
    },
    getNextDate (room) {
      if (!room) return
      let now = window.moment()
      let date = window.moment(room.date)
      let type = room.repeat_type
      if (type) {
        if (date.clone().unix() <= now.clone().unix()) {
          if (type === 1) {
            return window.moment()
          } else if (type === 2) {
            if (date.day() === now.day()) {
              return window.moment()
            } else {
              return mdtUtils.getDateNextWeekByDay(date.day())
            }
          }
        } else {
          return date
        }
      } else {
        return window.moment(room.date)
      }
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    showAddDoctor (show) {
      this.$refs.ModalAddMember.openModal(show)
      // window.$('#modalAddMember').modal(show ? 'show' : 'hide')
    },
    refreshMember () {
      this.getMdtMembers(this.showing_room?.id)
      this.showAddDoctor()
    },
    createMdtDisease () {
      this.$router.push({
        name: 'CreateMdtDisease',
        params: { room_id: this.showing_room.id }
      })
    },
    goToRoom (r) {
      let token = appUtils.getLocalToken()

      if (appUtils.isUseLiveHodoV2()) {
        let domainOrigin = window.location?.origin
        let domain = ''

        if (domainOrigin?.includes('http://localhost')) {
          domain = 'http://localhost:8068'
        } else if (
          process.env.NODE_ENV === 'development' &&
          !domainOrigin?.includes('http://localhost')
        ) {
          domain = 'https://dev.v2.live.hodo.app/'
        } else {
          domain = 'https://v2.live.hodo.app'
        }

        // const role = roleObj['conference']
        const room100ms_id = r?.room100ms_id
        const routeRaw = `${domain}/redirect.html?token=${token}&room_id=${room100ms_id}&type=mdt&role=${'host'}&lang=${
          this.$lang
        }`

        console.log(routeRaw)

        let linkElement = document.createElement('a')
        const linkId = `meeting_${room100ms_id}`
        linkElement.id = linkId
        linkElement.href = routeRaw
        window.document.body.appendChild(linkElement)

        const linkEl = document.getElementById(linkId)
        linkEl.setAttribute('rel', 'noopener noreferrer')
        !appUtils.checkIsMobile() && linkEl.setAttribute('target', '_blank')
        linkEl.click()
        linkEl.remove()

        // window.open(routeRaw, "_blank");
      } else {
        let dm = ''
        if (process.env.NODE_ENV === 'development') {
          dm = 'https://dev.meet.hodo.com.vn/#/'
        } else {
          dm = 'https://meet.hodo.com.vn/#/'
        }
        let routeData = dm + '_mdt/' + r?.room?.room_name + '?token=' + token
        window.open(routeData, '_blank')
      }
    },
    copyLink (link) {
      let url = document.createElement('input')
      document.body.appendChild(url)
      url.value = link
      url.select()
      url.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open({
        message: this.$t('multidisciplinary_board.lbl_copied_clipboard'),
        type: 'success'
      })
      url.remove()
    },
    formatDateDMY (date) {
      if (!date) return
      return window.moment(date).format('DD/MM/YYYY')
    },
    goToMdtSession (id) {
      this.$router.push({ name: 'SessionDetail', params: { id: id } })
    },
    goToFinishedDisease (type) {
      if (!this.showing_room) return
      this.$router.push({
        name: 'MdtListDisease',
        params: { id: this.showing_room?.id, type: type }
      })
    },
    handlePrintListOperation () {
      const mdtRoomId = this.showing_room?.id
      if (!mdtRoomId) return
      this.$router.replace({
        name: 'OperationPrint',
        params: {
          id: mdtRoomId
        },
        query: {
          date: this.filterDate
        }
      })
    },
    async handleGetTotalMdtCase (id) {
      if (!id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getFinishCaseMdtRoom(id)
        this.finished_cases_count = response?.data?.finished_cases_count
        this.pending_cases_count = response?.data?.pending_cases_count
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.form-control {
  height: 100% !important;
}
.flatpickr-calendar.open {
  background-color: #e4e4e4 !important;
}
.select-date {
  color: black !important;
  width: 100%;
  max-width: 237px;
  height: 47px;
}
.search-disease {
  width: 339px;
  height: 47px;
}
.item-doctor {
  height: 47px;
  cursor: pointer;
  margin-bottom: 16px;
  padding-left: 5px;
}
.item-disease {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-disease-right {
  width: 130px;
}
.item-disease-left {
  width: calc(100% - 130px);
  display: flex;
  align-items: center;
}
.btn-add-someting {
  color: #6d6e6f;
  border: dashed 1.5px #d3d3d3;
  border-radius: 14px;
  height: 47px;
  cursor: pointer;
  margin-bottom: 16px;
}
.mdt-detail-body {
  position: relative;
  height: calc(100vh - 100px);
  overflow-y: auto !important;
  overflow-x: hidden;
  border-radius: 10px;
  padding-bottom: 50px;
  padding-right: 32px;
}
.item-session {
  display: flex;
  align-items: center;
  height: 136px;
}
.h-136px {
  height: 136px;
}
</style>

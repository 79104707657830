<template>
  <div>
    <div
      class="modal fade"
      id="modalViewMdtDisease"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalViewMdtDiseaseLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl"
        role="document"
      >
        <div class="modal-content p-3">
          <div class="d-flex justify-content-between pb-3 border-bottom mb-3">
            <h5
              class="modal-title"
              id="modalViewMdtDiseaseLabel"
            >
              Thông tin ca bệnh
            </h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                class="robo-18-500"
              >&times;</span>
            </button>
          </div>
          <div
            class="p-3"
            v-if="mdt_disease"
          >
            <div class="mb-3">

              <p class="robo-14-400 mb-2 txt-grey-600">Tên ca bệnh</p>
              <div class="d-flex">
                <p class="robo-16-400 txt-black mb-0 w-75 max-line-1">
                  {{disease && disease.person && mdtUtils.getPersonInfoString(disease.person)}}
                </p>
                <div
                  class="w-25"
                  :set="sttClass = mdtUtils.getStatusDisease(mdt_disease.status)"
                >
                  <p
                    class="robo-16-500 text-right mb-0"
                    :class="sttClass.class"
                  >{{sttClass.text}}</p>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <p class="robo-14-400 mb-2 txt-grey-600">Bác sĩ đề xuất</p>
              <p class="robo-16-400 txt-black mb-0 w-75 max-line-1">
                {{mdt_disease.doctor && mdt_disease.doctor.name}}
              </p>
            </div>
            <div
              class="mb-3"
              v-if="disease"
            >
              <div class="row mb-3">
                <p class="robo-14-400 mb-0 txt-grey-600">Tóm tắt quá trình diễn biến bệnh, quá trình điều trị và chăm sóc</p>
              </div>
              <div class="row mb-3">
                <div class="col-2">
                  <p class="robo-14-400 mb-0 txt-black">Chẩn đoán: </p>
                </div>
                <div class="col-10">
                  <p class="pre-wrap robo-14-400 mb-0 txt-black">{{disease.diseases_name}}</p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-2">
                  <p class="robo-14-400 mb-0 txt-black">Tình trạng hiện tại:</p>
                </div>
                <div class="col-10">
                  <p class="pre-wrap robo-14-400 mb-0 txt-black">
                    {{disease.diseases_current_cure}}
                  </p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-2">
                  <p class="robo-14-400 mb-0 txt-black">Điều trị:</p>
                </div>
                <div class="col-10">
                  <p class="pre-wrap robo-14-400 mb-0 txt-black">
                    {{disease.diseases_previous_cure}}
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <p class="robo-14-400 mb-2 txt-grey-600">Mô tả</p>
              <p class="pre-wrap robo-14-400 mb-0 txt-black">
                {{mdt_disease.description}}
              </p>
            </div>
            <div class="mb-3">
              <p
                class="robo-14-400 mb-2 txt-grey-600"
                v-if="mdt_disease.start_time"
              >Thời gian hội chẩn: <span class="txt-black">{{formatDate(mdt_disease.start_time)}}</span></p>
            </div>
            <div
              class="mb-3"
              v-if="mdt_disease.cinconcluding && mdt_disease.concluding.pdf"
            >
              <p class="robo-18-400 txt-grey-600 mb-3">Tài liệu đính kèm</p>
              <div class="p-2 border radius-10 mb-2">
                <a
                  :href="mdt_disease.concluding.pdf"
                  target="_blank"
                  class="d-flex align-items-center txt-black cursor-pointer"
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2"
                  >
                    <path
                      d="M22.4359 11V17C22.4359 21 21.4359 22 17.4359 22H7.43585C3.43585 22 2.43585 21 2.43585 17V7C2.43585 3 3.43585 2 7.43585 2H8.93585C10.4359 2 10.7659 2.44 11.3359 3.2L12.8359 5.2C13.2159 5.7 13.4359 6 14.4359 6H17.4359C21.4359 6 22.4359 7 22.4359 11Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                    />
                  </svg>
                  Biên bản hội chẩn ngày {{mdt_disease.concluding.mdt_time && formatDate(mdt_disease.concluding.mdt_time)}}
                </a>
              </div>
            </div>

            <div class="mb-3">
              <p class="robo-18-400 txt-grey-600 mb-3">Tài liệu đính kèm</p>
              <div v-if="file_attach && file_attach.length">
                <div
                  v-for="item in file_attach"
                  :key="item.id"
                  class="p-2 border radius-10 mb-2"
                >
                  <a
                    href="javascript:;"
                    @click="openUrl(item.url)"
                    class="d-flex align-items-center txt-pri text-decoration-underline"
                  ><svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2"
                      v-if="item.type == 2"
                    >
                      <path
                        d="M15.4258 17.5H16.9358C19.9558 17.5 22.4358 15.03 22.4358 12C22.4358 8.98 19.9658 6.5 16.9358 6.5H15.4258"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.43585 6.5H7.93585C4.90585 6.5 2.43585 8.97 2.43585 12C2.43585 15.02 4.90585 17.5 7.93585 17.5H9.43585"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.43585 12H16.4359"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2"
                      v-if="item.type == 1"
                    >
                      <path
                        d="M22.4359 11V17C22.4359 21 21.4359 22 17.4359 22H7.43585C3.43585 22 2.43585 21 2.43585 17V7C2.43585 3 3.43585 2 7.43585 2H8.93585C10.4359 2 10.7659 2.44 11.3359 3.2L12.8359 5.2C13.2159 5.7 13.4359 6 14.4359 6H17.4359C21.4359 6 22.4359 7 22.4359 11Z"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                    </svg>
                    {{item.name}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mdtUtils from '../../utils/mdtUtils'
export default {
  name: 'MdtDiseaseDetail',
  props: ['mdt_disease_id'],
  data () {
    return {
      file_attach: [],
      mdt_disease: null,
      disease: null,
      mdtUtils
    }
  },
  mounted () {
    window.$(document).ready(function () {
      window.$('#modalViewMdtDisease').on('shown.bs.modal', function (e) {
      })
      window.$('#modalViewMdtDisease').on('hide.bs.modal', function (e) {
      })
    })
  },
  watch: {
    mdt_disease_id (id) {
      if (id) {
        this.showMdtDiseases(id)
      }
    }
  },
  methods: {
    async showMdtDiseases (id) {
      let self = this
      await self.$rf.getRequest('DoctorRequest').showMdtDiseases(id).then((r) => {
        self.mdt_disease = r.data
        self.disease = r.data.person_disease
        self.file_attach = r.data.attachment
      })
    },
    formatDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    openUrl (url) {
      var check = url.substring(0, 4)
      if (check === 'http') {
        window.open(url)
      } else {
        window.open('//' + url)
      }
    }
  }
}
</script>

<style scoped>
.pre-wrap {
  white-space: pre-wrap;
}
</style>
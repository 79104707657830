<template>
  <div>
    <div class="table-responsive">
      <table class="table table-vcenter table-mobile-md card-table">
        <thead>
          <tr>
            <th style="width: 280px;">{{ $t("multidisciplinary_board.lbl_patient") }}</th>
            <th style="width: 350px;">{{ $t("multidisciplinary_board.lbl_diagnose") }}</th>
            <th class="whitespace-nowrap">{{ $t("multidisciplinary_board.lbl_register_time") }}</th>
            <th
              class="whitespace-nowrap"
            >{{ $t("multidisciplinary_board.lbl_treatment_doctor_short") }}</th>
            <th class="whitespace-nowrap">{{ $t("multidisciplinary_board.lbl_hd_status") }}</th>
            <th class="whitespace-nowrap">{{ $t("multidisciplinary_board.lbl_mdtr") }}</th>
            <th class="whitespace-nowrap">{{ $t("multidisciplinary_board.lbl_smdtr") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="7" class="pt-4 cursor-pointer" @click="show_ts = !show_ts">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2"
                :class="!show_ts ? 'upsidedown' : ''"
              >
                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
              </svg>
              <span class="fs-16 fw-600 mb-0 txt-grey-900">
                {{
                $t("multidisciplinary_board.lbl_type_surgery")
                }}
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-if="session" v-show="show_ts">
          <tr
            v-for="d in tsDiseases"
            :key="d.id"
            @click="goToMdtDisease(d.id, $event)"
            class="cursor-pointer"
          >
            <td :data-label="$t('multidisciplinary_board.lbl_patient')">
              <p class="fs-14 fw-600 txt-grey-900 mb-0">{{ d.patient_name }}</p>
              <p class="fs-12 txt-grey-600 mb-0">
                ({{ mdtUtils.getYearOrders(d.patient_birthday)
                }}{{ d.patient_address ? " - " + d.patient_address : "" }})
              </p>
            </td>
            <td :data-label="$t('multidisciplinary_board.lbl_diagnose')">
              <!-- <p class="fs-14 fw-500 txt-grey-900 mb-0">{{ d.patient_diagnostic }}</p> -->
              <p class="fs-14 fw-500 txt-grey-900 mb-0">{{ patientDiagnostic(d) }}</p>
            </td>
            <td :data-label="$t('multidisciplinary_board.lbl_register_time')">
              <p
                class="fs-14 fw-500 txt-grey-700 mb-0 whitespace-nowrap"
              >{{ formatDateDMYHm(d.created_at) }}</p>
            </td>
            <td :data-label="$t('multidisciplinary_board.lbl_treatment_doctor')">
              <p
                class="fs-14 fw-500 txt-grey-700 mb-0 whitespace-nowrap"
              >{{ d.doctor && (d.doctor.name || d.doctor.User.name) }}</p>
            </td>
            <td
              :data-label="$t('multidisciplinary_board.lbl_hd_status')"
              :set="
                (sttClass = mdtUtils.getStatusDisease(
                  d.sended ? 3 : d.status
                ))
              "
            >
              <p
                class="fs-16 fw-500 mb-0 whitespace-nowrap"
                :class="sttClass.class"
              >{{ sttClass.text }}</p>
            </td>
            <td @click.stop :data-label="$t('multidisciplinary_board.lbl_mdt_report')">
              <div class="d-flex" v-if="d.concluding">
                <el-dropdown v-if="d.concluding.multilang">
                  <div>
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2 cursor-pointer"
                    >
                      <path
                        d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                        fill="#20409B"
                      />
                      <rect
                        x="1.25"
                        y="0.75"
                        width="30.5"
                        height="30.5"
                        rx="7.25"
                        stroke="#20419B"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                  <el-dropdown-menu slot="dropdown" v-if="d.concluding.multilang.length">
                    <el-dropdown-item
                      v-for="(item, index) in d.concluding.multilang"
                      :key="index"
                      :disabled="
                        !item.file_name_printing
                      "
                    >
                      <div
                        @click="openUrl({ fileName: item.file_name_printing })"
                      >{{ $t(`common.${appUtils.getLangCode(Number(item.lang ? item.lang : 1))}`) }}</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-dropdown v-if="d.concluding.multilang && (d.status > 1)">
                  <div>
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                    >
                      <path
                        d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                        fill="#20409B"
                      />
                      <path
                        d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                        fill="#20409B"
                      />
                      <rect
                        x="1.25"
                        y="0.75"
                        width="30.5"
                        height="30.5"
                        rx="7.25"
                        stroke="#20419B"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                  <el-dropdown-menu slot="dropdown" v-if="d.concluding.multilang.length">
                    <el-dropdown-item
                      v-for="(item, index) in d.concluding.multilang"
                      :key="index"
                      :disabled="
                        !item.file_name_printing
                      "
                    >
                      <div
                        @click="openUrl({ fileName: item.file_name_printing })"
                      >{{ $t(`common.${appUtils.getLangCode(Number(item.lang ? item.lang : 1))}`) }}</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </td>
            <td :data-label="$t('multidisciplinary_board.lbl_surgery_report')">
              <div @click.stop class="d-flex" v-if="d.operation && d.operation.file_name_printing">
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2 cursor-pointer"
                  @click="openUrl({ fileName: d.operation.file_name_printing })"
                >
                  <path
                    d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                    fill="#20409B"
                  />
                  <rect
                    x="1.25"
                    y="0.75"
                    width="30.5"
                    height="30.5"
                    rx="7.25"
                    stroke="#20419B"
                    stroke-width="1.5"
                  />
                </svg>
                <svg
                  v-if="d.status > 1"
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cursor-pointer"
                  @click="openUrl({ fileName: d.operation.file_name_printing })"
                >
                  <path
                    d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                    fill="#20409B"
                  />
                  <path
                    d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                    fill="#20409B"
                  />
                  <rect
                    x="1.25"
                    y="0.75"
                    width="30.5"
                    height="30.5"
                    rx="7.25"
                    stroke="#20419B"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colspan="7" class="pt-4 cursor-pointer" @click="show_mdt = !show_mdt">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2"
                :class="!show_mdt ? 'upsidedown' : ''"
              >
                <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
              </svg>
              <span class="fs-16 fw-600 mb-0 txt-grey-900">
                {{
                $t("multidisciplinary_board.lbl_mdt_title")
                }}
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-if="session" v-show="show_mdt">
          <tr
            v-for="d in mdtDiseases"
            :key="d.id"
            @click="goToMdtDisease(d.id)"
            class="cursor-pointer"
          >
            <td :data-label="$t('multidisciplinary_board.lbl_patient')">
              <p class="fs-14 fw-600 txt-grey-900 mb-0">{{ d.patient_name }}</p>
              <p class="fs-12 txt-grey-600 mb-0">
                ({{ getAges(d)
                }}{{ d.patient_address ? " - " + d.patient_address : "" }})
              </p>
            </td>
            <td :data-label="$t('multidisciplinary_board.lbl_diagnose')">
              <p class="fs-14 fw-500 txt-grey-900 mb-0">
                <!-- {{ d.patient_diagnostic }} -->
                {{ patientDiagnostic(d) }}
              </p>
            </td>
            <td :data-label="$t('multidisciplinary_board.lbl_register_time')">
              <p
                class="fs-14 fw-500 txt-grey-700 mb-0 whitespace-nowrap"
              >{{ formatDateDMYHm(d.created_at) }}</p>
            </td>
            <td :data-label="$t('multidisciplinary_board.lbl_treatment_doctor')">
              <p
                class="fs-14 fw-500 txt-grey-700 mb-0 whitespace-nowrap"
              >{{ d.doctor && (d.doctor.name || d.doctor.User.name) }}</p>
            </td>
            <td
              :data-label="$t('multidisciplinary_board.lbl_hd_status')"
              :set="
                (sttClass = mdtUtils.getStatusDisease(
                  d.sended ? 3 : d.status
                ))
              "
            >
              <p
                class="fs-16 fw-500 mb-0 whitespace-nowrap"
                :class="sttClass.class"
              >{{ sttClass.text }}</p>
            </td>
            <td @click.stop :data-label="$t('multidisciplinary_board.lbl_mdt_report')">
              <div class="d-flex" v-if="d.concluding">
                <el-dropdown v-if="d.concluding.multilang">
                  <div>
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-2 cursor-pointer"
                    >
                      <path
                        d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                        fill="#20409B"
                      />
                      <rect
                        x="1.25"
                        y="0.75"
                        width="30.5"
                        height="30.5"
                        rx="7.25"
                        stroke="#20419B"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                  <el-dropdown-menu slot="dropdown" v-if="d.concluding.multilang.length">
                    <el-dropdown-item
                      v-for="(item, index) in d.concluding.multilang"
                      :key="index"
                      :disabled="
                        !item.file_name_printing
                      "
                    >
                      <div
                        @click="openUrl({ fileName: item.file_name_printing })"
                      >{{ $t(`common.${appUtils.getLangCode(Number(item.lang ? item.lang : 1))}`) }}</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-dropdown v-if="d.concluding.multilang && (d.status > 1)">
                  <div>
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="cursor-pointer"
                    >
                      <path
                        d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                        fill="#20409B"
                      />
                      <path
                        d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                        fill="#20409B"
                      />
                      <rect
                        x="1.25"
                        y="0.75"
                        width="30.5"
                        height="30.5"
                        rx="7.25"
                        stroke="#20419B"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                  <el-dropdown-menu slot="dropdown" v-if="d.concluding.multilang.length">
                    <el-dropdown-item
                      v-for="(item, index) in d.concluding.multilang"
                      :key="index"
                      :disabled="
                        !item.file_name_printing
                      "
                    >
                      <div
                        @click="openUrl({ fileName: item.file_name_printing })"
                      >{{ $t(`common.${appUtils.getLangCode(Number(item.lang ? item.lang : 1))}`) }}</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </td>
            <td :data-label="$t('multidisciplinary_board.lbl_surgery_report')">
              <div @click.stop class="d-flex" v-if="d.operation && d.operation.file_name_printing">
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2 cursor-pointer"
                  @click="openUrl({ fileName: d.operation.file_name_printing })"
                >
                  <path
                    d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                    fill="#20409B"
                  />
                  <rect
                    x="1.25"
                    y="0.75"
                    width="30.5"
                    height="30.5"
                    rx="7.25"
                    stroke="#20419B"
                    stroke-width="1.5"
                  />
                </svg>
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="cursor-pointer"
                  @click="openUrl({ fileName: d.operation.file_name_printing })"
                >
                  <path
                    d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                    fill="#20409B"
                  />
                  <path
                    d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                    fill="#20409B"
                  />
                  <rect
                    x="1.25"
                    y="0.75"
                    width="30.5"
                    height="30.5"
                    rx="7.25"
                    stroke="#20419B"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="meta" class="flex justify-center">
      <el-pagination
        background
        layout="prev, pager, next"
        :pager-count="5"
        :current-page.sync="meta.current_page"
        :page-size="meta.per_page"
        :total="meta.total"
        class="el-pagination-mdt"
        hide-on-single-page
        @next-click="handleChangePage"
        @prev-click="handleChangePage"
        @current-change="handleChangePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'MdtTableList',
  props: {
    listData: {
      type: Array,
      required: true
    },
    metaData: Object,
    actionHandleFilter: Number
  },
  data () {
    return {
      room: null,
      session: this.listData || null,
      search: '',
      mdtUtils,
      appUtils,
      url_pdf: '',
      show_ts: true,
      show_mdt: true,
      selectedMdtDiseaseIds: [],
      dtr_member: {},
      doctor_id: null,
      master: null,
      ws: null,
      meta: this.metaData || {}
    }
  },
  mounted () {
    this.doctor_id = appUtils.getLocalUser()?.doctor?.id
  },
  computed: {
    langId () {
      return appUtils.getLangId(this.$lang || 'vi')
    },
    mdtRoomDiseases () {
      return _.map(this.session, item => {
        const sent = item?.public_mdt_case?.find(
          c =>
            moment(c.mdtDate + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss') >= moment()
        )
        const isOwner = this.doctor_id === item.doctor_id
        const isMaster =
          this.dtr_member?.role === 4 || this.dtr_member?.role === 1
        return {
          ...item,
          sendable:
            item?.concluding &&
            item?.status === 2 &&
            !sent &&
            (isOwner || isMaster),
          sended: !!sent
        }
      })
    },
    filteredAndSorted () {
      // function to compare names
      function compare (a, b) {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }
      let search_string = appUtils.removeVietnameseTones(
        this.search?.toLowerCase()
      )
      if (this.session && this.mdtRoomDiseases) {
        const result = this.mdtRoomDiseases
          .filter(d => {
            return (
              appUtils
                .removeVietnameseTones(d?.patientName?.toLowerCase())
                .includes(search_string) ||
              (d?.doctor?.name &&
                appUtils
                  .removeVietnameseTones(d?.doctor?.name?.toLowerCase())
                  .includes(search_string))
            )
          })
          .sort(compare)
        return result
      } else {
        return null
      }
    },
    mdtDiseases () {
      let data = this.filteredAndSorted
      if (data) {
        return data.filter(d => !d.type)
      } else {
        return []
      }
    },
    tsDiseases () {
      let data = this.filteredAndSorted
      if (data) {
        return data.filter(d => !!d.type)
      } else {
        return []
      }
    },
    approvedCase () {
      return this.session?.filter(d => d?.status === 2)
    },
    notApprovedCase () {
      return this.session?.filter(d => d?.status !== 2)
    },
    approvedCaseHC () {
      const result = _.filter(this.session, { type: 0 })
      return result?.filter(d => d?.status === 2)
    },
    notApprovedCaseHC () {
      const result = _.filter(this.session, { type: 0 })
      return result?.filter(d => d?.status !== 2)
    },
    approvedCaseTQM () {
      const result = _.filter(this.session, { type: 1 })
      return result?.filter(d => d?.status === 2)
    },
    notApprovedCaseTQM () {
      const result = _.filter(this.session, { type: 1 })
      return result?.filter(d => d?.status !== 2)
    },
    checkinMemberString () {
      if (!this.session?.mdt_checkins.length) return
      let string = this.session?.mdt_checkins?.map(d => d.doctor?.name)
      return string.join(', ')
    }
  },
  watch: {
    listData: {
      handler (data) {
        this.session = data
      },
      deep: true
    },
    metaData: {
      handler (data) {
        this.meta = data
      },
      deep: true
    }
    // actionHandleFilter () {
    //   this.handleChangePage(1)
    // }
  },
  methods: {
    patientDiagnostic (item) {
      if (item?.multilang?.length) {
        const langId = this.langId
        const r = item?.multilang.find(item => item.lang === langId)
        if (!r) return item?.patient_diagnostic
        else return r.patient_diagnostic
      }
      return item?.patient_diagnostic
    },
    showModalSend () {
      this.selectedMdtDiseaseIds = _.filter(
        this.mdtRoomDiseases,
        'selectedToSend'
      ).map(e => e.id)
      window.$('#modalSendCaseToOrg').modal('show')
    },
    async showSession () {
      let self = this
      await this.$rf
        .getRequest('DoctorRequest')
        .showMdtSession(this.$route.params.id)
        .then(async r => {
          self.session = r.data
          await self.showMdtRoom(r.data.mdt_room_id)
        })
    },
    async showMdtRoom (id) {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .showMdtRoom(id)
        .then(r => {
          self.room = r.data
          self.ws = r.data?.clinic?.name.toUpperCase()
          self.dtr_member = r?.data?.members?.find(
            m => m.doctor_id === this.doctor_id
          )
          self.master = r?.data?.members?.find(m => m.role === 1)
          // self.socketConnectMdtDiseases()
        })
    },
    formatDate (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatDateDMYHm (dt) {
      return window.moment(dt).utc().format('DD/MM/YYYY HH:mm')
    },
    backToMdtDetail () {
      this.$router.push({ name: 'ListMdt', query: { mdtid: this.room?.id } })
    },
    async openUrl ({ fileName, print = false }) {
      if (!fileName) {
        this.$toast.open({
          message: this.$t('multidisciplinary_board.lbl_notfound'),
          type: 'error'
        })
        return
      }

      try {
        window.open(fileName)
      } catch (error) {
        console.log(error)
      }
    },
    open (url, e) {
      e.stopPropagation()
      window.open(url)
    },
    print (url, e) {
      e.stopPropagation()
      window.open(url)
    },
    createMdtDisease () {
      this.$router.push({
        name: 'CreateMdtDisease',
        params: { room_id: this.room?.id }
      })
    },
    goToRoom (r) {
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = 'https://dev.meet.hodo.com.vn/#/'
      } else {
        dm = 'https://meet.hodo.com.vn/#/'
      }
      let token = appUtils.getLocalToken()
      let routeData = dm + '_mdt/' + r?.room?.room_name + '?token=' + token
      window.open(routeData, '_blank')
    },
    goToMdtDisease (id) {
      this.$router.push({ name: 'MdtDiseaseDetail', params: { id: id } })
    },
    stringToHTML (str) {
      if (!str) return ''
      var pattern = '</p>',
        re = new RegExp(pattern, 'g')
      let newstr = str.replace(re, '\n')
      newstr = newstr.replace(/<[^>]+>/g, '')
      newstr = this.decodeEntities(newstr).trim()
      return this.decodeEntities(newstr)
    },
    decodeEntities (encodedString) {
      var translate_re = /&(nbsp|amp|quot|lt|gt);/g
      var translate = {
        nbsp: ' ',
        amp: '&',
        quot: '"',
        lt: '<',
        gt: '>',
        'apos;': "'"
      }
      return encodedString
        .replace(translate_re, function (match, entity) {
          return translate[entity]
        })
        .replace(/&#(\d+);/gi, function (match, numStr) {
          var num = parseInt(numStr, 10)
          return String.fromCharCode(num)
        })
    },
    getAvatar (clinic) {
      if (clinic && clinic.avatar) {
        return process.env.VUE_APP_BASE_IMAGE_URL + 'api/logo/' + clinic.avatar
      }
    },
    gotoPrint () {
      const mdtSessionId = this.$route.params.id
      this.$router.push({
        name: 'HoiChanCasePrinter',
        params: { id: mdtSessionId }
      })
    },
    getAges (data) {
      return data?.alias && data?.alias.status && data?.alias.alias_age
        ? this.mdtUtils.getAges(data?.alias.alias_age)
        : this.mdtUtils.getAges(new Date(data?.patient_birthday))
    },
    handleChangePage (newPage) {
      this.page = newPage
      this.$emit('getData', newPage)
    }
  }
}
</script>

<style scoped>
.upsidedown {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}

#xxx th {
  padding: 2px !important;
}

.fw-400 {
  font-weight: normal !important;
}

.xxx {
  opacity: 0.001;
}
</style>

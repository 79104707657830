<template>
  <div style="mdt-info">
    <div class="mb-4 border-bottom d-flex justify-content-between header-info">
      <p class="mb-2 robo-16-500">Danh sách các ca bệnh</p>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="hideDisease"
        class="cursor-pointer"
      >
        <path
          d="M6 17.9998L17.9998 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.9998 17.9998L6 6"
          stroke="#292D32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class=" d-flex align-items-center justify-content-between mb-2">

      <div class="input-group border radius-10 search-disease mr-2">
        <div class="input-group-text border-0 bg-white radius-10">
          <svg
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.1006 21.5492C23.396 21.8448 23.396 22.3241 23.1006 22.6198C22.8051 22.9154 22.326 22.9154 22.0306 22.6198L18.3989 18.986C18.1034 18.6904 18.1034 18.2111 18.3989 17.9154C18.6943 17.6198 19.1734 17.6198 19.4689 17.9154L23.1006 21.5492ZM11.6704 19.2078C7.24108 19.2078 3.65039 15.615 3.65039 11.1832C3.65039 6.7514 7.24108 3.15869 11.6704 3.15869C16.0998 3.15869 19.6905 6.7514 19.6905 11.1832C19.6905 15.615 16.0998 19.2078 11.6704 19.2078ZM11.6704 17.6937C15.264 17.6937 18.1772 14.7789 18.1772 11.1832C18.1772 7.58759 15.264 4.67275 11.6704 4.67275C8.07681 4.67275 5.1636 7.58759 5.1636 11.1832C5.1636 14.7789 8.07681 17.6937 11.6704 17.6937ZM7.93783 10.5776C7.93783 10.8563 7.712 11.0823 7.43343 11.0823C7.15485 11.0823 6.92902 10.8563 6.92902 10.5776C6.92902 8.292 8.78082 6.43916 11.0651 6.43916C11.3437 6.43916 11.5695 6.66512 11.5695 6.94385C11.5695 7.22258 11.3437 7.44853 11.0651 7.44853C9.33797 7.44853 7.93783 8.84946 7.93783 10.5776Z"
              fill="#ABABAC"
            />
          </svg>
        </div>
        <input
          class="radius-10 form-control txt-black bg-white"
          v-model="search"
          @keyup.enter="getMdtDiseases()"
          placeholder="Nhập tên bác sĩ, bệnh nhân"
        />
      </div>
      <div class="input-group border radius-10 filter-status">
        <div class="input-group-text border-0 bg-white radius-10">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.33008 2.5H18.67C19.78 2.5 20.6901 3.40999 20.6901 4.51999V6.73999C20.6901 7.54999 20.1801 8.56 19.6801 9.06"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.3196 19.5699C14.3196 20.1799 13.9195 20.9799 13.4095 21.2899L11.9996 22.2C10.6896 23.01 8.86957 22.0999 8.86957 20.4799V15.1299C8.86957 14.4199 8.46957 13.5099 8.05957 13.0099L4.21954 8.96994C3.70954 8.45994 3.30957 7.55995 3.30957 6.94995"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.0701 17.0201C17.8374 17.0201 19.2701 15.5874 19.2701 13.8201C19.2701 12.0528 17.8374 10.6201 16.0701 10.6201C14.3028 10.6201 12.8701 12.0528 12.8701 13.8201C12.8701 15.5874 14.3028 17.0201 16.0701 17.0201Z"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.8701 17.6201L18.8701 16.6201"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <select
          v-model="status"
          class="form-select radius-10 txt-black"
          @change="getMdtDiseases()"
        >
          <option :value="null">Tất cả</option>
          <option :value="0">Đang chờ</option>
          <option :value="1">Đang diễn ra</option>
          <option :value="2">Đã có kết luận</option>
        </select>
      </div>
    </div>
    <div v-if="mdt_diseases && mdt_diseases.data && mdt_diseases.data.length">
      <div
        class="mb-3 border radius-10 p-3 d-flex justify-content-between"
        v-for="disease in mdt_diseases.data"
        :key="disease.id"
        @click="showDiseaseDetail(disease.id)"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6V26C32 29.3137 29.3137 32 26 32H6C2.68629 32 0 29.3137 0 26V6Z"
            fill="#F2F6FE"
          />
          <path
            d="M12 16.2002H19"
            stroke="#20419B"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 20.2002H16.38"
            stroke="#20419B"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 10H18C20 10 20 9 20 8C20 6 19 6 18 6H14C13 6 12 6 12 8C12 10 13 10 14 10Z"
            stroke="#20419B"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20 8.02002C23.33 8.20002 25 9.43002 25 14V20C25 24 24 26 19 26H13C8 26 7 24 7 20V14C7 9.44002 8.67 8.20002 12 8.02002"
            stroke="#20419B"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="item-info cursor-pointer">
          <p
            class="robo-14-400 mb-2 max-line-1 txt-black"
            v-if="disease"
          >{{disease.patient_diagnostic}}</p>
          <p
            class="robo-14-400 mb-2 max-line-1 txt-black"
            v-if="disease"
          >{{ mdtUtils.getMdtPersonInfoString(disease) }}</p>
          <p
            class="robo-14-400 mb-0 max-line-1 txt-grey-600"
            v-if="disease.doctor"
          >{{formatDMY(disease.created_at)}}{{disease.doctor ? ' | ' + disease.doctor.name : ''}}</p>
        </div>

        <div class="item-status">
          <p
            class="robo-16-500 mb-1 text-success"
            v-if="disease.status === 1"
          >
            Đang diễn ra
          </p>
          <div v-if="disease.status == 0">
            <p class="mb-3 robo-16-500 text-warning">
              Chờ hội chẩn
            </p>
          </div>
          <div v-if="disease.status == 2">
            <p class="mb-3 robo-16-500 txt-pri">
              {{disease.concluding && disease.concluding.pdf ? 'Có biên bản' : 'Có kết luận'}}
            </p>
            <div
              v-if="disease.doctor_id === doctor_id"
              class="btn btn-action bg-pri bd-pri text-white mb-1"
              @click="editBienBan(disease)"
            >
              Chỉnh sửa biên bản
            </div>
            <button
              href="javascript:;"
              class="txt-pri btn btn-action btn-viewbb"
              v-if="disease.concluding && disease.concluding.pdf"
              @click="showBienBan(disease)"
            >Xem biên bản</button>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a
          href="javascript:;"
          class="txt-pri robo-16-400"
          v-if="mdt_diseases.current_page < mdt_diseases.last_page"
          @click="getMdtDiseases(true)"
        >
          Xem thêm
        </a>
      </div>
    </div>
    <div v-if="mdt_diseases && (!mdt_diseases.data || !mdt_diseases.data.length)" class="text-center">
      <img
        src="../../../public/assets/images/icon/empty.svg"
        alt=""
        class="my-4"
        width="270"
      >
      <p class="robo-18-400">Chưa có ca bệnh nào </p>
      <button class="btn bg-pri bd-pri text-white w-50" @click="showAddDisease(true)">Thêm</button>
    </div>
  </div>
</template>

<script>
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
export default {
  name: 'MdtDisease',
  props: ['mdt_diseases'],
  components: {},
  data () {
    return {
      mdtUtils,
      appUtils,
      search: '',
      doctor_id: null,
      status: 0,
      page: 0,
      loading: false
    }
  },
  mounted () {
    let self = this
    self.doctor_id = appUtils.getLocalUser().doctor.id
  },
  methods: {
    showAddDisease (show) {
      this.$emit('showAddDisease', show)
    },
    refreshDisease () {
      this.$emit('refreshDisease')
    },
    hideDisease () {
      this.$emit('hideDisease')
    },
    showDiseaseDetail (id) {
      this.$emit('showDiseaseDetail', id)
    },
    showBienBan (mdt_disease) {
      this.$emit('showViewBienBan', false, mdt_disease)
      window.event.stopPropagation()
    },
    editBienBan (mdt_disease) {
      this.$emit('showViewBienBan', true, mdt_disease)
      window.event.stopPropagation()
    },
    getMdtDiseases (is_next) {
      if (this.loading) return
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500)
      let params = {
        status: this.status,
        search: this.search
      }
      this.$emit('getMdtDiseases', is_next, params)
    },
    formatDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>
.mdt-info {
  background: #fff;
  color: black;
}
.header-info {
  width: 100%;
}
.item-info {
  width: 290px;
}
.max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.item-status {
  width: 110px;
}
.search-disease {
  width: 339px;
  height: 47px;
}
.filter-status {
  color: black !important;
  width: 100%;
  max-width: 237px;
  height: 47px;
}
.form-control {
  height: 100% !important;
}
.btn-viewbb {
  border: 1.5px solid #20419B;
  border-radius: 8px;
}
.btn-action {
  height: 40px;
  width: 108px;
  top: 36px;
  border-radius: 8px;
  font-size: 10px;
}
.m-auto {
  margin: 0 auto;
}
</style>
<template>
  <div>
    <div class="hodo-el row items-center justify-content-xxl-end">
      <div class="col-4 col-xxl-5">
        <form @submit.prevent="enterSearch">
          <div>
            <label
              for="mdtSearchInput"
              class="font-bold mb-2"
            >{{ $t('multidisciplinary_board.lbl_search_mdt') }}</label>
            <div class="hodo-el-input-form relative h-10">
              <span
                @click="enterSearch"
                role="button"
                class="absolute z-10 top-1/2 left-2 -translate-y-1/2 -mt-4 pt-1.5"
              >
                <i class="el-icon-search text-lg leading-none" />
              </span>

              <el-input
                @keyup.enter="enterSearch"
                @input="debounceSearch"
                @clear="clearData"
                id="mdtSearchInput"
                name="mdt-search-input"
                :placeholder="$t('multidisciplinary_board.lbl_enter_name_or_person_code')"
                v-model="searchInput"
                clearable
                class="form-full-search w-full h-10 rounded-md overflow-hidden border bg-gray-50"
              ></el-input>
            </div>
          </div>
        </form>
      </div>

      <!-- Filters -->
      <div class="col-8 col-xxl-7">
        <div class="flex items-center">
          <div class="rounded-md mr-3 w-1/3">
            <label class="font-bold mb-2">{{ $t('multidisciplinary_board.lbl_attending_doctor') }}</label>
            <div class="rounded-md">
              <el-select
                class="w-full"
                name="doctor"
                v-model="filters.doctors.value"
                :default-first-option="true"
                :placeholder="$t('multidisciplinary_board.lbl_attending_doctor')"
                @change="handleFilters"
                @clear="clearData"
              >
                <el-option
                  v-for="item in filters.doctors.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="rounded-md mr-3 w-1/4">
            <label class="font-bold mb-2">{{ $t('multidisciplinary_board.lbl_select_type') }}</label>
            <el-select
              class="w-full"
              name="types"
              v-model="filters.types.value"
              :default-first-option="true"
              :placeholder="$t('multidisciplinary_board.lbl_select_type')"
              @change="handleFilters"
              @clear="clearData"
            >
              <el-option
                v-for="item in filters.types.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>

          <div class="rounded-md">
            <label class="font-bold mb-2">{{ $t('multidisciplinary_board.lbl_hd_status') }}</label>
            <div class="flex items-center">
              <el-select
                class="w-full"
                name="status"
                v-model="filters.status.value"
                :default-first-option="true"
                :placeholder="$t('multidisciplinary_board.lbl_hd_status')"
                @change="handleFilters"
                @clear="clearData"
              >
                <el-option
                  v-for="item in filters.status.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <button
                class="ml-3 btn btn-primary bg-pri bd-pri outline-none shadow-none h-10"
                @click="clearData"
              >{{ $t('multidisciplinary_board.lbl_clear_search') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="isShowFindingCase ? 'visible opacity-100 h-auto' : 'invisible opacity-0 h-0 overflow-hidden'"
      class="relative transition-all duration-300"
      v-loading="isLoading"
    >
      <div v-if="!listData.length">
        <div class="text-center text-xl py-3">
          <div>{{ $t('multidisciplinary_board.lbl_not_found_mdt') }}</div>
          <div class="my-3 text-sm">- {{ $t('multidisciplinary_board.lbl_or_can') }} -</div>
          <div class="flex items-center justify-center">
            <a
              href="javascript:;"
              class="fw-500 txt-pri fs-14"
              role="button"
              @click="clearData"
            >{{ $t("schedule_page.lbl_back") }}</a>
            <span class="mx-3">/</span>
            <a
              href="javascript:;"
              class="btn bg-pri bd-pri text-white py-2"
              role="button"
              @click="createMdtDisease"
            >{{ $t("multidisciplinary_board.btn_add_case") }}</a>
          </div>
        </div>
      </div>
      <div v-else>
        <MdtTableList
          :actionHandleFilter="actionHandleFilter"
          :listData="listData"
          :metaData="meta"
          @getData="getMdtDiseaseInRoom"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
::v-deep {
  .hodo-el {
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
    .form-full-search .el-input__inner {
      padding-left: 32px;
      font-size: inherit !important;
      background-color: transparent !important;
      text-align: inherit !important;
      transition: all 0.2s ease !important;
      border: none !important;

      &:focus {
        background-color: white !important;
      }
    }

    .el-input .el-input__clear {
      font-size: 18px !important;
      width: 24px !important;
      margin-top: -3px !important;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import MdtTableList from './MdtTableList.vue'
import _ from 'lodash'

export default {
  name: 'MdtRoomSearch',
  props: {
    room: Object,
    isShowFindingCase: Boolean
  },
  components: { MdtTableList },
  data () {
    const initialFilters = {
      doctors: {
        options: [
          {
            value: 'all',
            label: this.$t('schedule_page.lbl_all')
          }
        ],
        value: 'all'
      },
      types: {
        options: [
          {
            value: 'all',
            label: this.$t('schedule_page.lbl_all')
          },
          {
            value: 0,
            label: this.$t('multidisciplinary_board.lbl_type_mdt')
          },
          {
            value: 1,
            label: this.$t('multidisciplinary_board.lbl_type_surgery')
          }
        ],
        value: 'all'
      },
      status: {
        options: [
          {
            value: 'all',
            label: this.$t('schedule_page.lbl_all')
          },
          {
            value: 0,
            label: this.$t('multidisciplinary_board.lbl_status_not_approve')
          },
          {
            value: 2,
            label: this.$t('multidisciplinary_board.lbl_status_approved')
          }
        ],
        value: 'all'
      }
    }
    return {
      isLoading: false,
      debounce: null,
      typing: false,
      searchInput: '',
      listData: [],
      meta: {},
      initialFilters,
      filters: { ...initialFilters },
      params: {},
      actionHandleFilter: 0
    }
  },
  async created () {
    // await this.getClinicDoctors({ limit: 100 })
  },
  watch: {
    room (data) {
      if (data) {
        console.log(data)
        if (data) {
          const { members } = data
          this.setDoctorsFilter(members)
        }
      } else {
        this.clearData()
      }
    },
    clinicDoctors (data) {
      if (data) {
        // this.setDoctorsFilter(data)
      }
    }
  },
  computed: {
    ...mapGetters({
      clinicDoctors: 'clinicDoctorStore/allClinicDoctors'
    })
  },
  methods: {
    ...mapActions('clinicDoctorStore', ['getClinicDoctorsRequest']),
    debounceSearch () {
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        if (!this.searchInput?.length) {
          this.clearData()
        }
      }, 600)
    },
    async enterSearch () {
      this.isLoading = true
      this.$emit('setSearchInput', this.searchInput)
      this.$emit('setIsShowFindingCase', Boolean(this.searchInput?.length))
      await this.getMdtDiseaseInRoom()
    },
    clearData () {
      this.listData = []
      this.meta = {}
      this.searchInput = ''
      this.filters = {
        doctors: { ...this.initialFilters.doctors, value: 'all' },
        types: { ...this.initialFilters.types, value: 'all' },
        status: { ...this.initialFilters.status, value: 'all' }
      }
      this.params = {}
      this.setDoctorsFilter()
      this.$emit('setSearchInput', this.searchInput)
      this.$emit('setIsShowFindingCase', false)
    },
    async getMdtDiseaseInRoom (page) {
      if (!this.room?.id) return

      this.isLoading = true

      try {
        const params = {
          query_patient: this.searchInput,
          limit: 10,
          page: page || 1,
          ...this.params
        }
        const resp = await this.$rf
          .getRequest('DoctorRequest')
          .getMdtDiseaseInRoom(this.room?.id, params)
          .finally()
        if (resp.data?.code === 404) return

        const data = resp.data?.data || []
        this.listData = data
        this.meta = resp.data?.meta || {}
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    },
    createMdtDisease () {
      this.clearData()
      this.$emit('createMdtDisease')
    },
    async handleFilters (value) {
      this.params = {
        doctor_id:
          this.filters.doctors.value !== 'all'
            ? this.filters.doctors.value
            : '',
        type:
          this.filters.types.value !== 'all' ? this.filters.types.value : '',
        status:
          this.filters.status.value !== 'all' ? this.filters.status.value : ''
      }

      this.actionHandleFilter++
      await this.getMdtDiseaseInRoom()
      this.$emit('setIsShowFindingCase', true)
    },
    async getClinicDoctors ({ limit = 100, params = {} }) {
      try {
        let paramsData = {
          ...params,
          limit
        }

        await this.getClinicDoctorsRequest(paramsData)
      } catch (error) {
        console.log(error)
      }
    },
    setDoctorsFilter (data) {
      const doctors = data || this.room?.members || []
      this.filters.doctors = {
        options: [
          {
            value: 'all',
            label: this.$t('schedule_page.lbl_all')
          }
        ],
        value: 'all'
      }
      _.forEach(doctors, item => {
        this.filters.doctors.options.push({
          value: item?.doctor_id,
          label: item?.doctor?.name
        })
      })
    }
  },
  beforeDestroy () {
    this.clearData()
  }
}
</script>

<style>
</style>
